'use client'

import { useRouter } from 'next/navigation'

import { Button } from '@/components/atoms/ui/button'

export default function NotFound() {
  const router = useRouter()

  return (
    <main className="grid min-h-full px-6 py-24 text-center bg-white space-y-7 place-items-center sm:py-32 lg:px-8">
      <h1 className="text-3xl font-bold text-primary-blue lg:text-5xl">404</h1>
      <div className="space-y-3">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">Halaman tidak ditemukan</h2>
        <p className="text-base leading-7 text-gray-600">Maaf, kami tidak dapat menemukan halaman yang Anda cari.</p>
      </div>
      <Button onClick={() => router.back()}>Kembali</Button>
    </main>
  )
}
